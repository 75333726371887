import pageType from 'Addons/search/pageType.ts';

export default {
  page: {
    typeToSelectorMap: {
      [pageType.home]: 'body.cms-home',
      [pageType.category]: 'body.catalog-category-view',
      [pageType.product]: 'body.catalog-product-view',
    },
  },
};
