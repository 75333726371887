
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function productDataRT () {
    function scopeAttributeField1(prop, propIndex) {
        var attribute = prop.value;
        var field = prop.field;
        return _createElement('div', {
            'className': 'value',
            'key': prop.field
        }, '\n      ', window.Convermax.productAttrNameMap(field), ': \n      ', !Array.isArray(attribute) ? [attribute] : null, Array.isArray(attribute) ? [attribute.join(', ')] : null);
    }
    function repeatProp2(prop, propIndex, attribute, field) {
        return scopeAttributeField1.apply(this, [
            prop,
            propIndex
        ]);
    }
    return _createElement('div', { 'className': 'additional-attributes-wrapper table-wrapper' }, _createElement.apply(this, [
        'div',
        { 'className': 'product attribute description' },
        _map(this.productData, repeatProp2.bind(this))
    ]));
}
        export const componentNames = []