export default {
  getLocalPreselection: () =>
    window.cm_category
      ? [
          {
            field: 'category',
            term: window.cm_category,
            treeLevel: window.cm_category.split('>').length - 1,
          },
        ]
      : [],
};
